import Input from '../../Fields/Input'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Form, FormikProps } from 'formik'
import React from 'react'

export interface UpdateLostPasswordFields {
    password: string
    passwordConfirm: string
}

interface UpdateLostPasswordFormProps extends FormikProps<UpdateLostPasswordFields> { }

const UpdateLostPasswordForm: React.FC<UpdateLostPasswordFormProps> = ({ }) => {
    return (
        <Form className="w-full" style={{
            display: "flex", justifyContent: "center",
            flexDirection: "column", alignItems: "center", border: "solid 1px #f4f4f4", marginBottom: "15px",
            backgroundColor: "#f9fafb", paddingBottom: "30px", borderRadius: "10px"
        }}>
            <div style={{ flexDirection: "column", alignItems: "center", width: "90%" }} id="inscription">
                <h2>Réinitialiser votre mot de passe</h2>
                <Input name="password" label="Renseigner*" placeholder="Nouveau mot de passe" type="password" icon={faLock} style={{ width: "350px", backgroundColor: "white" }} />
                <Input
                    label="Confirmer*"
                    name="passwordConfirm"
                    placeholder="Confirmer votre mot de passe"
                    icon={faLock}
                    type="password"
                    className="mt-5"
                    style={{ width: "350px", backgroundColor: "white" }}
                />
            </div>

            <div className="flex justify-center mt-10">
                <button style={{ width: '260px' }} className="cta my-2 flex-grow-0" type="submit">
                    Soumettre
                </button>
            </div>
        </Form>
    )
}

export default UpdateLostPasswordForm