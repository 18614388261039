import UpdateLostPasswordForm, { UpdateLostPasswordFields } from '../../components/Forms/UpdateLostPasswordForm'
import validateUpdateLostPasswordForm from '../../components/Forms/UpdateLostPasswordForm/validate'
import useQueryParam from '../../hooks/useQueryParam'
import useService from '../../hooks/useService'
import routes from '../../routes'
import { updateLostPassword } from '../../services/iam'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'


const UpdateLostPassword: React.FC = () => {
    let [token] = useQueryParam<string | undefined>('token')
    const history = useHistory()

    useEffect(() => {

        if (!token) {
            history.push(routes.LOGIN)
        }
    }, [token])

    const { service } = useService({
        onSuccess: () => {
            alert('Votre mot de passe a bien été mis à jour')
            history.push(routes.LOGIN)
        },
        onError: (error: { code: number }) => {
            if (error && error.code === 400) {
                alert('Votre demande a expiré, veuillez relancer la procédure')
            } else {
                alert('Un problème est survenu')
            }
        }
    })

    const onSubmit = (values: UpdateLostPasswordFields) => {
        service(() =>
            updateLostPassword({
                token: token as string,
                password: values.password
            })
        )
    }

    return (
        <div
            className="wrap"
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
                paddingTop: '20px',
            }}
        >
            <Formik
                initialValues={{ password: '', passwordConfirm: '' }}
                onSubmit={onSubmit}
                component={UpdateLostPasswordForm}
                validate={validateUpdateLostPasswordForm}
            />
        </div>

    )
}

export default UpdateLostPassword
