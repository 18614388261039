import { useState, useEffect } from 'react'
import request from 'superagent'
import { format, isBefore, isAfter } from 'date-fns'

const useMaintenance = () => {
  const [isInMaintenance, setIsInMaintenance] = useState(false)
  const [verifyMaintenance, setVerifyMaintenance] = useState<{
    dateStart: string
    dateEnd: string
    isActive: boolean
  }>()

  const load = async () => {
    try {
      const res = await request.get(`${process.env.REACT_APP_API_URL}/maintenance`)

      const result: { dateStart: string; dateEnd: string; isActive: boolean } = res.body
      const currentDate = new Date()
      const startDate = new Date(result.dateStart)
      const endDate = new Date(result.dateEnd)

      const dateStart = format(new Date(startDate), 'dd/MM/yyyy HH:mm')
      const dateEnd = format(new Date(endDate), 'dd/MM/yyyy HH:mm')

      setVerifyMaintenance({ dateStart, dateEnd, isActive: result.isActive })

      const isMaintenance = isBefore(startDate, currentDate) && isAfter(endDate, currentDate) && result.isActive

      setIsInMaintenance(isMaintenance)
    } catch (error) {
      console.log('Paramètres de maintenance non définis')
    }
  }
  useEffect(() => {
    load()
  }, [])

  return {
    isInMaintenance,
    verifyMaintenance,
  }
}

export default useMaintenance
