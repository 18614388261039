import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {
  useTable,
  useResizeColumns,
  useBlockLayout,
  useSortBy,
  useFilters,
  Column,
  Cell,
  ColumnInstance,
  Row,
  CellProps,
  Filters,
  IdType,
  FilterValue,
} from 'react-table'
import Input from '../../Fields/Input'
import Loader from '../../Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import './styles.pcss'
import Select from '../../Fields/Select'
import DatePicker from '../../Fields/DatePicker'
import { format } from 'date-fns'
import usePagination from '../../../hooks/usePagination'
import Pagination from '../Pagination'
import './styles.css'

// Appeler les brouillons dans le tableau

interface ITableData {
  [key: string]: any
}

interface IFilterOption {
  label?: string
  value: any
}

export type FilterType = 'select' | 'selectMultiple' | 'date' | 'checkbox' | 'default'

export type ITableColumn<T extends object> = {
  type?: 'default' | 'date' | 'boolean' | 'datetime'
  filtered?: boolean
  filterType?: FilterType
  filterOptions?: IFilterOption[]
  isSortable?: boolean
} & Column<T>

interface Filter<T> {
  id: IdType<T>
  value: FilterValue
}

export interface IFilter<T extends {}> extends Filter<T> {
  type: FilterType
}

export type IFilters<T extends {}> = IFilter<T>[]

interface ITableBaseProps<T extends object> {
  // Structure of the columns for react-table
  columns: Array<ITableColumn<T>>
  // Data to be displayed in the table
  data: ITableData[]
  // Triggers everytime a page of the table is rendered providing the selected page number
  infiniteScroll?: boolean
  // Displays a spinner instead of table's data
  isLoading?: boolean
  // ClassName prop of the container
  className?: string
  enableFilters?: boolean
  //page numbers
  loadData: (pageNumber: number, filters?: IFilter<T>[], sorting?: ISortingSettings) => void
}

interface ITableNotPaginatedProps extends ITableBaseProps<any> {
  paginated?: false
  pagination?: never
}

export interface IPaginationParams {
  total: number
  limit: number
  skip: number
}

interface ITablePaginatedProps extends ITableBaseProps<any> {
  paginated: true
  pagination?: IPaginationParams
}

export type ITableFilterProps<D extends {}> = { column: ColumnInstance<D> & ITableColumn<D> }

export interface ISortingSettings {
  id: string
  // Is Ascending
  asc?: boolean
}

export type ITableProps = ITableNotPaginatedProps | ITablePaginatedProps

const Table: React.FC<ITableProps> = ({
  columns,
  data = [],
  paginated = false,
  isLoading = false,
  infiniteScroll,
  className = '',
  loadData,

  ...props
}) => {
  const pagination = useMemo(() => {
    if (
      !props.pagination ||
      props.pagination.limit === undefined ||
      props.pagination.skip === undefined ||
      props.pagination.total === undefined
    ) {
      return undefined
    } else {
      const { limit, total, skip } = props.pagination

      const numberOfPages = Math.ceil(limit >= total ? 1 : (total + (limit - (total % limit))) / limit)

      const currentPage = skip / limit + 1

      return {
        numberOfPages,
        currentPage,
        skip,
        limit,
        total,
      }
    }
  }, [props.pagination])

  const [paginatedData, setPaginatedData] = useState<any[]>([])

  const scrollRef = useRef<HTMLDivElement>(null)

  const scrollTableToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
      })
    }
  }

  useEffect(() => {
    if (infiniteScroll) {
      if (!pagination || !data) {
        setPaginatedData([])
      } else if (pagination.currentPage === 1) {
        scrollTableToTop()
        setPaginatedData(data)
      } else {
        const currentData = paginatedData ? paginatedData : []

        setPaginatedData([...currentData, ...data])
      }
    }
  }, [data])

  const [sortingSettings, setSortingSettings] = useState<ISortingSettings | undefined>()

  const defaultColumn: Partial<ITableColumn<any>> = useMemo(
    () => ({
      type: 'default',
      filterType: 'default',
      width: 200,
      Cell: (cellProps: CellProps<any>) => {
        const column = cellProps.column as ColumnInstance<any> & ITableColumn<any>

        switch (column.type) {
          case 'boolean':
            return <div className="text-xl pl-3">{cellProps.value ? <FontAwesomeIcon icon={faCheckSquare} /> : ''}</div>

          case 'date':
            return format(new Date(cellProps.value), 'dd/MM/yyyy')

          case 'datetime':
            return format(new Date(cellProps.value), 'dd/MM/yyyy HH:mm')

          default:
            return cellProps.value ? cellProps.value : ''
        }
      },
      filterPlaceHolder: 'Filtrer',
      Filter: ({ column }: any) => {
        const onChange = (newValue: any) => {
          column.setFilter(newValue)
        }

        const filterType = column.filterType

        if (filterType === 'select') {
          const filterOptions = column.filterOptions
          return <Select onChange={onChange} items={filterOptions} />
        } else if (filterType === 'date') {
          return <DatePicker onChange={onChange} value={column.filterValue} placeholder="Filtre date" />
        } else {
          return <Input name={''} onChange={onChange} placeholder={column.filterPlaceHolder} />
        }
      },
    }),
    []
  )

  const onLoadData = (pageNumber: number) => {
    const filters = addTypesToFilters(state.filters)

    loadData(pageNumber, filters, sortingSettings)
  }

  const handlePageChange = (pageNumber: number) => {
    onLoadData(pageNumber)
  }

  const { pageNumber, onPageChange, setPageNumber } = usePagination(handlePageChange)

  useEffect(() => {
    if (pagination && pagination.skip !== undefined && pagination.limit > 0) {
      const newPageNumber = pagination.skip / pagination.limit + 1

      setPageNumber(newPageNumber)
    }
  }, [pagination])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state }: any = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      manualFilters: true,
      autoResetFilters: false,
    } as any,
    useResizeColumns,
    useBlockLayout,
    useFilters,
    useSortBy
  )

  const addTypesToFilters = (filters: Filters<any>): IFilter<any>[] => {
    return filters.map((filter) => {
      const columnItem = columns.find((column) => column.id === filter.id)

      const type = columnItem && columnItem.filterType ? columnItem.filterType : 'default'

      return {
        ...filter,
        type,
      }
    })
  }

  // load the initial page on mount
  useEffect(() => {
    onLoadData(1)
  }, [])

  const onFiltersChange = () => {
    onLoadData(1)
    scrollTableToTop()
  }
  // Resets the page number to 1 when inter filters change

  // Resets the page number to 1 when inter filters change

  const onScroll = () => {
    if (scrollRef && scrollRef.current && pagination) {
      const currentHeight = scrollRef.current.scrollTop
      const totalHeight = scrollRef.current.scrollHeight
      const clientHeight = scrollRef.current.clientHeight
      const remainingToScroll = totalHeight - currentHeight - clientHeight

      if (remainingToScroll < 30 && pagination.currentPage < pagination.numberOfPages && !isLoading) {
        const newPageNumber = pagination.currentPage + 1
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        {...getTableProps()}
        className={`table-auto ${className}  max-w-full align-middle border-b border-gray-200 shadow hidden md:block `}
      >
        <div className="w-full max-w-full min-w-full border-grey-200">
          {headerGroups.map((headerGroup: any) => (
            <div key={headerGroup.getHeaderGroupProps().key}>
              <div
                style={{ width: '100%' }}
                {...headerGroup.getHeaderGroupProps()}
                className="max-w-full min-w-full bg-red-100 border-b border-gray-200 "
              >
                {headerGroup.headers.map((column: ITableColumn<any> & ColumnInstance<any>, index: number) => {
                  const onColumnClick = () => {
                    if (column.isSortable) {
                    }
                  }
                  const isSorted = sortingSettings && sortingSettings.id === column.id
                  const isAscending = Boolean(isSorted && sortingSettings && sortingSettings.asc)

                  return (
                    <div
                      {...column.getHeaderProps()}
                      className={`${
                        column.isSortable ? 'cursor-pointer' : ''
                      } flex-grow overflow-hidden flex justify-between items-center
                       py-2 text-xs font-medium leading-4 tracking-wider text-left text-black-500 border-4
                      uppercase`}
                      key={index}
                      onClick={onColumnClick}
                    >
                      <div className="text-xs font-semibold w-full h-full flex items-center" style={{ paddingLeft: 5 }}>
                        {column.render('Header')}
                        {isSorted && <i className={`lnr lnr-arrow-${isAscending ? 'down' : 'up'} ml-2`}></i>}
                        <div
                          // @ts-ignore
                          {...column.getResizerProps()}
                          // @ts-ignore
                          className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
        <div
          {...getTableBodyProps()}
          style={{
            maxHeight: infiniteScroll ? '630px' : undefined,
            overflowY: infiniteScroll ? 'scroll' : undefined,
            paddingBottom: infiniteScroll ? '60px' : undefined,
          }}
          onScroll={onScroll}
          ref={scrollRef}
          className="bg-gray-200"
          // className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-indigo-600"
        >
          {(!isLoading || infiniteScroll) &&
            rows.map((row: Row, i: number) => {
              prepareRow(row)
              const isOdd = i % 2

              const rowProps = row.getRowProps()
              //tableDraft

              return (
                <Fragment key={`row-${row.id}`}>
                  <div
                    {...rowProps}
                    style={{ ...rowProps.style, width: undefined, minHeight: '40px', borderTop: 'solid 1px grey' }}
                    className="w-full max-w-full"
                  >
                    {row.cells.map((cell: Cell, index: number) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className={`flex-grow pl-3 py-1 text-xs ${isOdd ? 'bg-gray-50' : ''} overflow-hidden ${
                            i === rows.length - 1 ? '' : ''
                          }`}
                        >
                          <div className="flex items-center h-full">{cell.render('Cell')}</div>
                        </div>
                      )
                    })}
                  </div>
                </Fragment>
              )
            })}
          {!rows.length && !isLoading && <div className="text-center text-slate-500 p-8">Aucune donnée</div>}
          {isLoading && (
            <div className="w-full h-full py-28">
              <Loader />
            </div>
          )}
        </div>
      </div>

      <div className="md:hidden block ">
        <div {...getTableProps()} style={{ height: '100%' }}>
          {(!isLoading || infiniteScroll) &&
            rows.map((row: Row, i: number) => {
              prepareRow(row)
              const isOdd = i % 2

              const rowProps = row.getRowProps()
              //tableDraft

              return (
                <div
                  style={{ ...rowProps.style, width: undefined, minHeight: '40px', borderTop: 'solid 1px grey' }}
                  className="w-full max-w-full border border-red flex flex-col"
                  key={i}
                >
                  {row.cells.map((cell: Cell, index: number) => {
                    return (
                      <span className="flex responsive-cell" key={`mobile-row-${index}`}>
                        <span
                          style={{ borderBottom: 'solid 1px grey', borderRight: 'solid 1px grey' }}
                          className="flex-grow flex justify-center p-2 bg-red-100 text-base font-semibold responsive-header text-center"
                        >
                          {cell.render('Header')}
                        </span>
                        <span
                          style={{ borderBottom: 'solid 1px grey' }}
                          className="flex-grow flex justify-start items-center text-sm pl-3 overflow-hidden"
                        >
                          {cell.render('Cell')}
                        </span>
                      </span>
                    )
                  })}
                </div>
              )
            })}
          {!rows.length && !isLoading && <div className="text-center text-slate-500 p-8">Aucune donnée</div>}
          {isLoading && (
            <div className="w-full h-full py-28">
              <Loader />
            </div>
          )}
        </div>
      </div>

      {!infiniteScroll && paginated && pagination && (
        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={pagination.limit}
          totalItemsCount={pagination.total}
          onChange={onPageChange}
        />
      )}
    </div>
  )
}

export default Table
