import Button from '../../Button'
import Input from '../../Fields/Input'
import { faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import routes from '../../../routes'
import { Field, Form, FormikProps } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'

export interface LostPasswordFields {
    email: string
}

interface LostPasswordFormProps extends FormikProps<LostPasswordFields> { }

const LostPasswordForm: React.FC<LostPasswordFormProps> = ({ }) => {

    return (
        <Form className="w-full" style={{
            display: "flex", justifyContent: "center",
            flexDirection: "column", alignItems: "center", border: "solid 1px #f4f4f4", marginBottom: "15px",
            backgroundColor: "#f9fafb", paddingBottom: "30px", borderRadius: "10px"
        }}>
            <div style={{ flexDirection: "column", alignItems: "center", width: "90%" }} id="inscription">
                <h2>Veuillez renseigner votre email</h2>
                <Input name="email" label="Email*" placeholder="Adresse E-mail" className="mb-5" icon={faEnvelope} style={{ width: "350px", backgroundColor: "white" }} />
            </div>
            <button style={{ width: '260px' }} className="cta my-2 flex-grow-0" type="submit">
                Envoyer
            </button>
            <Link to={routes.LOGIN}>
                <div className="flex justify-center mt-10 underline decoration-primary underline-offset-4 hover:opacity-50">
                    <div className="flex items-center gap-3">
                        <FontAwesomeIcon icon={faArrowLeft} /> <div>Retour à la page de connexion</div>
                    </div>
                </div>
            </Link>
        </Form>
    )
}

export default LostPasswordForm
