import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div className="wrap center">
      <h1>Erreur 404</h1>
      <p>La page que vous cherchez n'existe pas (ou plus).</p>
      <p>
        Voulez-vous retourner à la <a href="https://moncompte.howdens-cuisines.com/">page d'accueil</a>&nbsp;?
      </p>
    </div>
  )
}

export default NotFound
