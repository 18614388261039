import { persistKey } from './../persist'
import { LoginResponse } from '../../services/iam'
import { setJwtToken, deleteJwtToken } from '../../utils/session'
import { destroy } from '../../utils/storage'

export enum EActionTypes {
  SET_LOGIN = 'SET_LOGIN',
  LOGOUT = 'LOGOUT',
}

export type LoginPayload = {
  [EActionTypes.SET_LOGIN]: LoginActionPayload
  [EActionTypes.LOGOUT]: undefined
}

export interface LoginActionPayload extends LoginResponse {
  depositRef: string | null
}

export const setLogin = (response: LoginActionPayload) => {
  setJwtToken(response.accessToken)

  return {
    type: EActionTypes.SET_LOGIN,
    payload: response
  }
}

export const logout = () => {
  deleteJwtToken()
  destroy(persistKey)

  return {
    type: EActionTypes.LOGOUT
  }
}
