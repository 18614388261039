import React from 'react'
import ReactLoaderSpinner from 'react-loader-spinner'

const LoaderV2: React.FC = () => {
 
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactLoaderSpinner type="ThreeDots" color="#2E3748" height={20} width={20} />
    </div>
  )
}

export default LoaderV2