import React from 'react'

const Home: React.FC = () => {
  return (
    <main>
      <div className="wrap">
        <div id="home">
          <div id="banner">
            <div>
              <h1>Bienvenue dans votre espace professionnel</h1>
              <p>
                <a href="/subscribe" className="cta">
                  Ouvrez votre compte
                </a>
              </p>
            </div>
          </div>
          <div id="subscribe">
            <p>
              En tant que <strong className="color">professionnel</strong>,<br />
              le <strong>partenariat</strong> avec Howdens Cuisine <br />
              offre de <strong className="color">nombreux avantages</strong>.
            </p>
          </div>
          <div id="home_content">
            <div>
              <img src="assets/img/artisan.jpg" width="282" height="302" alt="Artisan Howdens Pro" />
              <img src="assets/img/depot.jpg" width="282" height="302" alt="Dépôt Howdens Pro" />
            </div>
            <div>
              <div className="storage">
                <h2>
                  Stock disponible
                  <br />
                  IMMÉDIATEMENT
                </h2>
                <p>
                  <strong>50 modèles</strong> de cuisines
                  <br />
                  tendances disponibles
                  <br />
                  <strong>immédiatement</strong> en dépôt.
                </p>
              </div>
              <div className="plan_3d">
                <h2>
                  Service gratuit de <br />
                  MODÉLISATION 3D
                </h2>
                <p>
                  Un accompagnement expert <strong>gratuit</strong>
                  <br />
                  pour réaliser à vos côtés et avec
                  <br />
                  vos clients des <strong>plans 3D</strong> qualitatifs.
                </p>
              </div>
              <div className="showroom">
                <h2>
                  Showroom pour
                  <br />
                  ACCUEILLIR VOS CLIENTS
                </h2>
                <p>
                  Un showroom pour accueillir <br />
                  vos clients <strong>6 jours sur 7</strong> dans lequel
                  <br />
                  vous serez toujours comme chez vous.
                </p>
              </div>
              <div className="caisson">
                <h2>
                  Caissons
                  <br />
                  GARANTIS 25 ANS
                </h2>
                <p>
                  Une fabrication traditionnelle,
                  <br />
                  <strong>garantie 25 ans</strong> <br />
                  sur les caissons montés{' '}
                </p>
              </div>
              <div className="sub">
                <a href="/subscribe" className="cta">
                  Ouvrez votre compte
                </a>
              </div>
            </div>
            <div>
              <img src="assets/img/designer.jpg" width="282" height="302" alt="Artisan Howdens Pro" />
              <img src="assets/img/caisson.jpg" width="282" height="302" alt="Dépôt Howdens Pro" />
            </div>
          </div>
          <div id="find_depot">
            <h3>
              <span>Trouvez votre dépôt le plus proche</span>
            </h3>
            <p>
              {' '}
              Engagé à vos côtés <strong>depuis plus de 15 ans</strong>, Howdens Cuisines se tient à votre disposition
              pour vous accompagner dans votre activité. <br />
              Prenez contact avec votre dépôt.
            </p>
            <p>
              <a href="https://www.howdens-cuisines.com/nos-depots/" className="cta" rel="noopener" target="_blank">
                Trouvez votre dépôt
              </a>
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Home
