
import React, { useEffect } from 'react'
import { Form, FormikProps } from 'formik'
import Input from 'src/components/Fields/Input'
import './styles.css'

export interface DraftFilterFields {
    search: string
}

interface DraftFilterForm extends FormikProps<DraftFilterFields> {
    onSearchChange: (value: string) => void
}

const DraftFiltersForm: React.FC<DraftFilterForm> = ({ onSearchChange, values }) => {
    useEffect(() => {
        onSearchChange(values.search)
    }, [values])

    return (
        <Form style={{ width: '100%' }}>
            <div className="draft-filters w-full flex justify-center items-center" >
                <Input
                    className="input-responsive"
                    style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                    name="search"
                    placeholder=" Rechercher par société, SIREN, TVA ou responsable"
                />
            </div>

        </Form>)
}

export default DraftFiltersForm