import React, { useEffect, useState } from 'react'
import Loader from 'src/components/Loader'
import { fetchCaptchaData, ICaptchaData } from 'src/services/captcha'
import './styles.css'

interface ICaptchaProcess {
  onSuccess: () => void
  setError: (message: string) => void
}

const CaptchaProcess: React.FC<ICaptchaProcess> = ({ onSuccess, setError }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string>()
  const [captchaData, setCaptchaData] = useState<ICaptchaData>()

  const loadCaptcha = async () => {
    setIsLoading(true)
    try {
      const data = await fetchCaptchaData()
      setCaptchaData(data)
    } catch (error) {
      console.log('failed to fetch captcha data')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadCaptcha()
  }, [])

  const onSelectImage = (id: string) => {
    if (captchaData && id === captchaData.goodPicture) {
      onSuccess()
    } else {
      setError('Erreur: veuillez sélectionner la bonne image')
      setSelectedImage(undefined)
      loadCaptcha()
    }
  }

  return (
    <div className="captcha-container">
      {isLoading && <Loader />}
      {!isLoading && captchaData && (
        <>
          <div className="captcha-text">Pour continuer, veuillez sélectionner l'image dans le bon sens</div>
          <div className="captcha-images">
            {captchaData.images.map((item) => {
              const onClick = () => {
                onSelectImage(item.id)
              }

              const isSelected = selectedImage === item.id

              return (
                <div key={item.id} className={`captcha-img ${isSelected ? 'selected' : ''}`} onClick={onClick}>
                  <img src={item.base64} alt="captcha" />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default CaptchaProcess
