import React from 'react'
import ReactLoaderSpinner from 'react-loader-spinner'

const Loader: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <ReactLoaderSpinner type="ThreeDots" color="#2E3748" height={50} width={50} />
    </div>
  )
}

export default Loader
