export interface ICaptchaData {
  goodPicture: string
  images: {
    id: string
    base64: string
  }[]
}

export const fetchCaptchaData = async (): Promise<ICaptchaData> => {
  let result = await fetch(`${process.env.REACT_APP_CAPTCHA_API_URL}/captcha`)
  return result.json()
}