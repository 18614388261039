import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { Formik, FormikProps, validateYupSchema } from 'formik'
import { useHistory } from 'react-router-dom'
import { validate, validateSubscribeForm } from '../../components/Forms/SubscribeForm/validate'
import FormSubscribe, { ISubscribeFields } from '../../components/Forms/SubscribeForm'
import client from '../../services/feathers'
import useNetwork from 'src/hooks/useNetwork'
import { Deposit, fetchAllDeposits } from 'src/services/subscribe'
import { useStore } from 'src/stores/index'
import { setDepositOperators, setDeposits } from 'src/stores/deposits/actions'
import { setDeleteForms, setForms } from 'src/stores/drafts/actions'
import DraftList from 'src/components/Tables/DraftList'
import { v4 as uuidv4 } from 'uuid'
import CaptchaProcess from 'src/components/Captcha/CaptchaProcess'
//@ts-ignore
import Rodal from 'rodal'
import './styles.css'
import { format, isBefore } from 'date-fns'
import { getJwtToken } from 'src/utils/session'
import Login from '../Login'

interface IForm {
  depot: string | null
}

const Form: React.FC<IForm> = ({ depot }) => {
  const subscribeService = client.service('subscriptions')

  const [isPending, setIsPending] = useState(false)
  const [errors, setErrors] = useState<any[]>([])
  const [serverError, setServerError] = useState({})
  const [initialValues, setInitialValues] = useState<Partial<ISubscribeFields>>({
    tempId: uuidv4(),
    optinCgv: false,
    prefixMobile: '+33',
    optinMarketEmail: true,
    optinMarketSms: true,
    depositId: undefined,
    nearZipCode: undefined,
    comment: '',
    optinDate: '',
    addressNumber: '',
    billByEmail: true,
    sepaWanted: false,
    sepaAccepted: false,
    job: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [operators, setOperators] = useState([])
  const [depositData, setDepositData] = useState<Deposit>()
  const history = useHistory()
  const { dispatch, state } = useStore()

  //récupérer les données de la blacklist dès que le composant est monté
  const [blacklist, setBlacklist] = useState<string[]>([])

  const blacklistService = client.service('blacklist')
  useEffect(() => {
    const loadBlacklist = async () => {
      const response = await blacklistService.find()
      setBlacklist(response.data.map((item: any) => item.phone))
    }
    loadBlacklist()
  }, [])

  const [draftsToSynchronise, setDraftsToSynchronise] = useState<ISubscribeFields[]>([])
  const [draftToError, setDraftToError] = useState('une erreur est survenue')
  const [showDraftModal, setShowDraftModal] = useState(false)
  const [showTokenModal, setShowTokenModal] = useState(false)

  const onSubmit = async (values: ISubscribeFields, test: boolean = false) => {
    if (depot && isTokenNotValid()) {
      return
    }

    setIsPending(true)

    const toSend = JSON.parse(JSON.stringify(values))

    toSend.mobile = `${toSend.prefixMobile}${toSend.mobile}`
    setServerError({})
    setErrors([])
    try {
      const res = await subscribeService.create({
        ...toSend,
        addressNumber: values.addressNumber && values.addressNumber !== '' ? values.addressNumber : '0',
        isPublic: !Boolean(depot),
        operatorId: state.login.currentUser ? state.login.currentUser.id : null,
      })

      history.push(`/uploads/${res.id}/${res.secutityToken}`)
      dispatch(setDeleteForms(values.tempId!))
    } catch (error: any) {
      if (!Array.isArray(error.errors)) {
        setServerError(error)
      }
      if (error.errors) setErrors(error.errors)
      else {
        console.log(error)
        setErrors([{ internal: 'Erreur serveur' }])
      }
    } finally {
      setIsPending(false)
    }
  }

  //DRAFTS
  const onSaveDraft = async (values: ISubscribeFields, isValid: boolean) => {
    dispatch(setForms({ ...values, optinDate: format(new Date(), 'yyyy-MM-dd'), isValid, depotRef: depot }))
    alert('Votre brouillon a bien été enregistré')
    window.location.reload()
  }

  const isTokenExpired = (token: string) => {
    const accessToken: any = jwtDecode(token)
    const tokenExpiration = accessToken.exp

    const currentDate = new Date()
    const endDate = new Date(tokenExpiration * 1000)
    const isTokenExpired = isBefore(endDate, currentDate)

    return isTokenExpired
  }

  const isTokenNotValid = () => {
    const isTokenCompare = isTokenExpired(getJwtToken())
    if (isTokenCompare) {
      setShowTokenModal(true)
    }
    return isTokenCompare
  }

  const onSynchronize = (values: ISubscribeFields | ISubscribeFields[]) => {
    if (isTokenNotValid()) {
      return
    } else if (!isOnline) {
      alert('Veuillez vous connecter à internet pour synchroniser les demandes d`ODC')
    } else {
      setDraftsToSynchronise(Array.isArray(values) ? values : [values])
      setShowDraftModal(true)
    }
  }
  //onSynchronise à faire

  const handleSynchronize = async (
    values: ISubscribeFields,
    tempId: string,
    mustRedirectAfterwards: boolean = true
  ) => {
    setShowDraftModal(false)
    if (isTokenNotValid()) {
      return
    }
    try {
      const toSend = JSON.parse(JSON.stringify({ ...values, captchaValidation: true } as ISubscribeFields))

      toSend.mobile = `${toSend.prefixMobile}${toSend.mobile}`

      const res = await subscribeService.create({
        ...toSend,
        addressNumber: values.addressNumber && values.addressNumber !== '' ? values.addressNumber : '0',
        isPublic: !Boolean(depot),
      })

      if (mustRedirectAfterwards) {
        history.push(`/uploads/${res.id}/${res.secutityToken}`)
      }
      dispatch(setDeleteForms(tempId))
    } catch (error: any) {
      alert('Une ou plusieurs demande(s) n’ont pas été synchronisées car des champs obligatoires sont manquants')
      console.log({ error })
    }
  }

  useEffect(() => {
    try {
      getDeposit()
    } catch (error) {
      console.log({ error })
    }
  }, [depot])

  const getDeposit = async () => {
    if (depot) {
      const depositsService = client.service('deposits')

      let depositData

      if (isOnline) {
        const res = await depositsService.find({
          query: { ref: depot },
        })

        depositData = res.data[0]
      } else {
        if (state.deposits.list) {
          depositData = state.deposits.list.find((item) => item.ref === depot)
        }
      }

      setDepositData(depositData)

      const newInitialValues = { ...initialValues }
      newInitialValues.depositId = depositData.id
      newInitialValues.nearZipCode = depositData.name
      setInitialValues(newInitialValues)

      const operatorsServices = client.service('operators')

      let operatorsData

      if (isOnline) {
        const resOperators = await operatorsServices
          .find({
            query: {
              $limit: 10000,
              depotCode: depot,
            },
          })
          .catch((e: any) => console.log(e))

        operatorsData = resOperators.data

        if (operatorsData) {
          dispatch(setDepositOperators(depot, operatorsData))
        }
      } else {
        const depositOperators = state.deposits.operatorsByDeposit.find((item) => item.depositRef === depot)

        operatorsData = depositOperators ? depositOperators.operators : []
      }

      setOperators(operatorsData)
    }
    setIsLoading(false)
  }

  const isOnline = useNetwork()

  const onFetchDeposits = async () => {
    try {
      const deposits = await fetchAllDeposits()

      if (deposits) {
        dispatch(setDeposits(deposits))
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isOnline) {
      onFetchDeposits()
    }
  }, [isOnline])

  const draftEdit = (draftInitialValues: ISubscribeFields) => {
    setInitialValues(draftInitialValues)
  }

  return (
    <>
      {depot !== null && (
        <DraftList
          depot={depot}
          draftEdit={draftEdit}
          onSynchronize={onSynchronize}
          depositData={depositData}
          isOnline={isOnline}
        />
      )}
      <Rodal visible={showDraftModal} onClose={() => {}} showCloseButton={false} className="rodal-draft">
        {showDraftModal && (
          <CaptchaProcess
            onSuccess={async () => {
              await Promise.all(
                draftsToSynchronise.map(async (draft) => {
                  await handleSynchronize(draft, draft.tempId as string, draftsToSynchronise.length === 1)
                })
              )
              if (draftsToSynchronise.length > 1) {
                alert('Vos brouillons ont été synchronisés avec succès')
              } else {
                alert('Votre brouillon a été synchronisé avec succès')
              }
            }}
            setError={() => draftToError}
          />
        )}
      </Rodal>
      <Rodal visible={showTokenModal} onClose={() => {}} showCloseButton={false} className="rodal-draft">
        {showTokenModal && <Login onSuccess={() => setShowTokenModal(false)} />}
      </Rodal>
      <div className="wrap" style={{ position: 'relative', paddingTop: '20px' }}>
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values, false)}
            validate={validate}
            validationSchema={() =>
              validateSubscribeForm(isOnline, Boolean(depot), blacklist, initialValues.prefixMobile || '+33')
            }
            enableReinitialize
          >
            {(formikProps: FormikProps<ISubscribeFields>) => (
              <FormSubscribe
                onSaveDraft={onSaveDraft}
                depositCode={depot}
                isPending={isPending}
                {...formikProps}
                errorsServer={errors}
                otherErrors={serverError}
                operators={operators}
                isOnline={isOnline}
                loading={isLoading}
              />
            )}
          </Formik>
        )}
      </div>
    </>
  )
}

export default Form
