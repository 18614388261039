import LostPasswordForm, { LostPasswordFields } from '../../components/Forms/LostPasswordForm/index'
import validateLostPasswordForm from '../../components/Forms/LostPasswordForm/validate'
import useService from '../../hooks/useService'
import { appLostPassword } from '../../services/iam'
import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'

const LostPassword: React.FC = () => {
    const { service } = useService({
        onSuccess: () => {
            alert('Votre demande a bien été prise en compte')
        },
        onError: () => {
            alert('Un problème est survenu')
        }
    })


    const onSubmit = (values: LostPasswordFields, formik: FormikHelpers<LostPasswordFields>) => {
        service(() => appLostPassword(values))
        formik.resetForm()
    }

    return (

        <div
            className="wrap"
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
                paddingTop: '20px',
            }}>
            <Formik
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
                component={LostPasswordForm}
                validationSchema={validateLostPasswordForm}
            />
        </div>

    )
}

export default LostPassword