import React, { createContext, useContext, useReducer } from 'react'
import depositReducer, {
  initialState as depositsInitialState,
  DepositsState,
  DepositActionTypes,
} from './deposits/reducer'

import draftReducer, {
  initialState as draftsInitialState,
  FormState,
  FormActionTypes,
} from './drafts/reducer'

import loginReducer,
{
  initialState as loginInitialState, ILoginState, LoginActionTypes
} from './login/reducer'

import { usePersist, applyPersistToInitialState } from './persist'

interface IStore {
  deposits: DepositsState,
  drafts: FormState,
  login: ILoginState
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key
  }
  : {
    type: Key
    payload: M[Key]
  }
}

export type ActionType = LoginActionTypes


export type ActionTypes = DepositActionTypes


export type ActionsTypes = FormActionTypes



interface IContextProps {
  state: IStore
  dispatch: React.Dispatch<any>
}

const StoreContext = createContext({} as IContextProps)

interface IStoreProvider {
  children: React.ReactNode
}

const mainReducer = ({ deposits, drafts, login }: IStore, action: any) => ({
  deposits: depositReducer(deposits, action),
  drafts: draftReducer(drafts, action),
  login: loginReducer(login, action),
})

export const StoreProvider: React.FC<IStoreProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(
    mainReducer,
    applyPersistToInitialState({
      deposits: depositsInitialState,
      drafts: draftsInitialState,
      login: loginInitialState,
    })
  )

  usePersist<IStore>(state, ['deposits', 'drafts', 'login'], state.login.currentUser !== null)

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)
