import { ISubscribeFields } from 'src/components/Forms/SubscribeForm'
import { ActionMap } from './../index'
import { EActionTypes, SubcribeFormPayload } from './actions'

export type FormActionTypes = ActionMap<SubcribeFormPayload>[keyof ActionMap<SubcribeFormPayload>]

interface FormList {
    subscribe: ISubscribeFields[]
}

export interface SavedDraft extends ISubscribeFields {
    isValid: boolean
    depotRef: string | null
}


export interface FormState {
    list: SavedDraft[]
}

export const initialState: FormState = {
    list: [],
}

const reducer = (state: FormState, action: FormActionTypes): FormState => {

    switch (action.type) {

        case EActionTypes.SET_FORMS:

            const newDraftList = [...state.list]
            const listIndex = newDraftList.findIndex((item) => item.tempId === action.payload.tempId)

            if (listIndex === -1) {
                newDraftList.push(action.payload)
            } else {
                newDraftList[listIndex] = action.payload

            }

            return {
                ...state,
                list: newDraftList,
            }

        case EActionTypes.DELETE_FORM:

            const deleteDraftList = [...state.list]
            const deleteListIndex = deleteDraftList.findIndex((item) => item.tempId === action.payload)

            if (deleteListIndex !== -1) {
                deleteDraftList.splice(deleteListIndex, 1)
            }

            return {
                ...state,
                list: deleteDraftList,
            }

        default:
            return {
                ...state,
            }
    }
}

export default reducer
