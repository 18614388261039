import React, { useEffect } from 'react'
import { Form, FormikProps } from 'formik'
import Input from 'src/components/Fields/Input'
import './styles.css'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export interface DepositFilterFields {
  search: string
}

interface DepositFilterForm extends FormikProps<DepositFilterFields> {
  onSearchChange: (value: string) => void
}

const DepositFiltersForm: React.FC<DepositFilterForm> = ({ onSearchChange, values }) => {
  useEffect(() => {
    onSearchChange(values.search)
  }, [values])



  return (
    <Form style={{ width: '100%' }}>
      <div className="Deposit-filters w-full flex justify-center items-center pt-10">
        <Input
          className="input-responsive"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
          name="search"
          placeholder=" Rechercher par nom, référence dépôt ou code postal"
          icon={faSearch}
        />
      </div>
    </Form>
  )
}

export default DepositFiltersForm
