import { Deposit, Operator } from 'src/services/subscribe'

export enum EActionTypes {
  SET_DEPOSITS = 'SET_DEPOSITS',
  SET_DEPOSIT_OPERATORS = 'SET_DEPOSIT_OPERATORS',
}

export type DepositPayload = {
  [EActionTypes.SET_DEPOSITS]: Deposit[]
  [EActionTypes.SET_DEPOSIT_OPERATORS]: { depositRef: string; operators: Operator[] }
}

export const setDeposits = (response: Deposit[]) => {
  return {
    type: EActionTypes.SET_DEPOSITS,
    payload: response,
  }
}

export const setDepositOperators = (depositRef: string, operators: Operator[]) => {
  return {
    type: EActionTypes.SET_DEPOSIT_OPERATORS,
    payload: { depositRef, operators },
  }
}
