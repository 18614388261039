import Table, { ITableColumn } from '../../Tables/Table'
import { ISubscribeFields } from '../../Forms/SubscribeForm'
import React, { useMemo, useState } from 'react'
import { useStore } from 'src/stores/index'
import Input from '../../Fields/Input'
import { Form, Formik, FormikProps } from 'formik'
import { faTrash, faArrowsRotate, faEdit, faExclamationCircle, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import useEffectNotOnFirstRender from 'src/hooks/useEffectNotOnFirstRender'
import DraftFiltersForm, { DraftFilterFields } from 'src/components/Forms/DraftFiltersForm'
import { setDeleteForms } from 'src/stores/drafts/actions'
import { SavedDraft } from 'src/stores/drafts/reducer'
import { Deposit } from 'src/services/subscribe'

interface DraftListProps {
  depot: string
  depositData?: Deposit
  draftEdit: (draftInitialValues: ISubscribeFields) => void
  onSynchronize: (values: SavedDraft | SavedDraft[]) => void
  isOnline: boolean
}

const DraftList: React.FC<DraftListProps> = ({ depot, draftEdit, onSynchronize, depositData, isOnline }) => {
  const { dispatch, state } = useStore()

  const [checkedDrafts, setCheckedDrafts] = useState<string[]>([])



  //depot ref
  const depotDrafts = useMemo(() => {
    return state.drafts.list.filter((item) => item.depotRef === depot)
  }, [depot, state.drafts.list])

  const [drafts, setDrafts] = useState(depotDrafts.slice(0, 10))

  const columns = useMemo<ITableColumn<ISubscribeFields>[]>(
    () => [
      {
        id: 'action',
        minWidth: 100,
        Cell: (cell: any) => {
          return (
            <span>
              <input
                type="checkbox"
                name={`isSelected_${cell.row.index}`}
                checked={checkedDrafts.includes(cell.row.original.tempId)}
                onClick={() => selectDraft(cell.row.original.tempId)}
                readOnly
              />
            </span>
          )
        },
      },
      {
        Header: 'Date de creation',
        accessor: 'optinDate',
        id: 'optinDate',
        type: 'date',
      },

      {
        Header: 'Nom société',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Siren / TVA',
        id: 'siren',
        accessor: (values: ISubscribeFields) => {
          if (values.country === 'FR') {
            return values.siren
          } else {
            return values.tva
          }
        },
      },
      {
        Header: 'Nom du responsable',
        id: 'lastname',
        accessor: 'lastname',
      },
      {
        Header: 'Opérateur',
        id: 'operator',
        accessor: 'operator',
      },
      {
        id: 'edit',
        minWidth: 60,
        Cell: (cell: any) => {
          return (
            <div className="flex flex-row space-x-6 pt-2.5">
              <button type="button" onClick={() => draftEdit(cell.row.original)}>
                <FontAwesomeIcon icon={faEdit} />
              </button>

              {cell.row.original.isValid ? (
                <button
                  title="Veuillez vous connecter à internet pour la synchronisation."
                  type="button"
                  onClick={() => {
                    if (!isOnline) {
                      alert('Connectez-vous à internet pour la synchronisation.')
                    } else {
                      onSynchronize(cell.row.original)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
              ) : (
                <button type="button" title="Veuillez renseigner tous les champs obligatoire.">
                  <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red' }} />
                </button>
              )}

              <button type="button" onClick={() => deleteDraft(cell.row.original.tempId)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          )
        },
      },
    ],

    [drafts, checkedDrafts, isOnline]
  )

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 5,
    total: depotDrafts.length,
  })

  const loadData = (pageNumber: number) => {
    const skip = (pageNumber - 1) * pagination.limit

    const newDrafts = depotDrafts.filter((item) => {
      const fieldsToSearch: string[] = ['name', 'lastname', 'siren', 'tva']

      if (!search) {
        return true
      }

      const match = fieldsToSearch.find(
        // @ts-ignore
        (field) => item[field] && item[field].toLowerCase().includes(`${search.toLowerCase()}`)
      )

      return match
    })

    setPagination({ ...pagination, skip: skip, total: newDrafts.length })

    const end = skip + pagination.limit

    setDrafts(newDrafts.slice(skip, end))
  }

  const selectDraft = (tempId: string) => {
    const newCheckedDrafts = [...checkedDrafts]
    if (newCheckedDrafts.includes(tempId)) {
      const indexDraft = newCheckedDrafts.findIndex((item) => item === tempId)
      newCheckedDrafts.splice(indexDraft, 1)
    } else {
      newCheckedDrafts.push(tempId)
    }
    setCheckedDrafts(newCheckedDrafts)
  }

  //alert(`La demande d'ODC a été supprimé avec succès`)

  const deleteDraft = (tempId: string) => {
    if (window.confirm("êtes-vous sure de vouloir supprimer l'ODC?")) {
      dispatch(setDeleteForms(tempId))
    } else {
      alert(`La demande de suppression est annulée`)
      loadData(1)
    }
  }

  const [search, setSearch] = useState<string>('')

  const onSearchChange = (value: string) => {
    setSearch(value)
  }

  useEffectNotOnFirstRender(() => {
    loadData(1)
  }, [search, depotDrafts])

  const allDeleteDraft = () => {
    if (checkedDrafts.length >= 1 && window.confirm('êtes-vous sure de vouloir supprimer les ODC?')) {
      checkedDrafts.forEach((draft) => {
        dispatch(setDeleteForms(draft))
      })
    } else {
      alert('Veuillez sélectionner au minima une ODC')
    }

    loadData(1)
  }

  const allSelected = () => {
    const allDraftSelected = drafts.map((draft) => {
      return draft.tempId as string
    })

    if (drafts.length !== checkedDrafts.length) {
      setCheckedDrafts(allDraftSelected)
    } else if (drafts.length) {
      setCheckedDrafts([])
    }
  }

  return (
    <div
      style={{
        border: 'solid 1px grey',
        backgroundColor: '#EBEBEB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
      }}
    >
      <h2 className="draft-h2">
        {' '}
        Dépot - {depositData ? depositData.name : ''} - {depot} - ODC à synchroniser
      </h2>
      <Formik
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center' }}
        initialValues={{
          search: '',
        }}
        onSubmit={async () => { }}
      >
        {(formikProps: FormikProps<DraftFilterFields>) => (
          <DraftFiltersForm {...formikProps} onSearchChange={onSearchChange} />
        )}
      </Formik>
      <div
        style={{
          border: 'solid 1px grey',
          width: '90%',
          marginBottom: '20px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Table columns={columns} data={drafts} pagination={pagination} loadData={loadData} paginated />

        <div
          className="responsive-draft flex flex-col md:flex-row"
          style={{
            backgroundColor: '#F9FAFB',
            padding: '12px 0 12px 15px',
            width: '100%',
            display: 'flex',
            borderTop: 'solid 1px grey',
            alignItems: 'center',
          }}
        >
          <input
            type="checkbox"
            name="allIsSelected"
            checked={Boolean(drafts.length === checkedDrafts.length && checkedDrafts.length)}
            //onChange={() => { console.log('test checkbox') }}
            onClick={allSelected}
            readOnly
          />
          <button
            className={`button-draft my-4 md:my-0 ${!isOnline ? 'opacity-25' : ''}`}
            style={{ backgroundColor: '#C4C4FF', width: '260px' }}
            type="button"
            onClick={async () => {
              if (!isOnline) {
                alert('Connectez-vous à internet pour la synchronisation.')
              } else {
                const toSynchronise: SavedDraft[] = []

                checkedDrafts.forEach((tempId) => {
                  const selectedDraft = drafts.find((item) => item.tempId === tempId)

                  if (selectedDraft) {
                    toSynchronise.push(selectedDraft)
                  }
                })

                const invalidDraft = toSynchronise.find(item => !item.isValid)

                if (invalidDraft) {
                  alert("Un ou plusieurs brouillon sélectionné n'est pas valide, et donc ne peut être synchronisé")
                } else if (checkedDrafts.length >= 1) {
                  onSynchronize(toSynchronise)
                } else {
                  alert('Veuillez sélectionner au minima une ODC')
                }
              }
            }}
          >
            <div style={{ marginRight: 5 }}>
              <FontAwesomeIcon icon={faArrowsRotate} />
            </div>{' '}
            SYNCHRONISER LA SELECTION
          </button>
          <button
            className="button-draft-2"
            style={{ backgroundColor: '#FFD3D3', marginLeft: '0px', width: '260px' }}
            type="button"
            onClick={allDeleteDraft}
          >
            <div style={{ marginRight: 5 }}>
              {' '}
              <FontAwesomeIcon icon={faTrash} />
            </div>{' '}
            EFFACER LA SELECTION
          </button>
        </div>
      </div>
    </div>
  )
}

export default DraftList
