import { IFeathersDefaultFilters } from '../types/feathers.types'
import { IFilters, ISortingSettings } from '../components/Tables/Table'


export const getPaginationParams = (page: number | undefined, limit: number = 10) => {
  return page !== undefined
    ? {
      $limit: limit,
      $skip: (page - 1) * limit,
    }
    : {
      $skip: 0,
      $limit: 500,
    }
}

export function formatFiltersForFeathersService<T extends {}, F extends IFeathersDefaultFilters>(
  filters?: IFilters<T>,
  dbType: 'mongo' | 'default' = 'default'
): F {
  const apiFilters: any = {}

  const searchFilter = (id: string, value: string) => {
    if (value !== '') {
      if (dbType === 'mongo') {
        apiFilters[id] = { $search: value }
      } else {
        apiFilters[id] = { $like: `${value}%` }
      }
    }
  }

  const selectFilter = (id: string, value: any) => {
    apiFilters[id] = value
  }

  const multiSelectFilter = (id: string, value: string[]) => {
    if (value.length) {
      apiFilters[id] = value
    }
  }

  const booleanFilter = (id: string, value: boolean) => {
    apiFilters[id] = value ? 1 : 0
  }

  if (filters) {
    filters.forEach(({ id, value, type }) => {
      switch (type) {
        case 'select':
          selectFilter(id, value)
          break

        case 'selectMultiple':
          multiSelectFilter(id, value)
          break

        case 'checkbox':
          booleanFilter(id, value)
          break

        default:
          searchFilter(id, value)
      }
    })
  }

  return apiFilters
}

export const formatSortingFilter = (settings?: ISortingSettings) => {
  return settings
    ? {
      $sort: {
        [settings.id]: settings.asc ? 1 : -1
      }
    }
    : {}
}
