import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

export default function useQueryParam<T>(key: string): [T | null] {

    const location = useLocation();

    let searchParams = new URLSearchParams(location.search);

    let paramValue = searchParams.get(key)

    return [paramValue as T | null]
}
