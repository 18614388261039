import { ActionMap } from './../index'
import { EActionTypes, LoginPayload } from './actions'
import { User } from '../../services/iam'

export type LoginActionTypes = ActionMap<LoginPayload>[keyof ActionMap<LoginPayload>]

export interface ILoginState {
  currentUser: User | null
  depositRef: string | null
}




export const initialState: ILoginState = {
  currentUser: null,
  depositRef: null
}




const reducer = (state: ILoginState, action: LoginActionTypes): ILoginState => {

  switch (action.type) {
    case EActionTypes.SET_LOGIN:
      return {
        ...state,
        currentUser: action.payload.user,
        depositRef: action.payload.depositRef
      }

    case EActionTypes.LOGOUT:
      return {
        ...state,
        currentUser: null,
        depositRef: null
      }

    default:
      return {
        ...state
      }
  }
}

export default reducer
