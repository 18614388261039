import React from 'react'



interface Maintenance {
    dateStart: string
    dateEnd: string
    isActive: boolean
}

const Maintenance: React.FC<Maintenance> = ({ dateStart, dateEnd }) => {

    return (
        <div className="center" style={{
            paddingTop: '50px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        }} >
            <img src="assets/img/ui/repair-tool.png" alt="img-maintenance" style={{ width: "200px", paddingBottom: "50px" }} />
            <h1>Votre site est en cours de maintenance </h1>
            <div style={{ color: '#d22630', fontSize: '20px' }}> {`${dateStart} - ${dateEnd}`} </div>
            <br></br>
            <p>
                Voulez-vous retourner à la page d' <a href="https://subscribe.howdens.whatson.io/">Inscription Pro - Howdens Cuisines</a>&nbsp;? <br></br> <br></br>
                ou rendez vous sur le
                site <a href="https://www.howdens-cuisines.com/?_ga=2.2641456.1201021283.1663060131-1525544452.1661253621">Grand Public</a>
            </p>
            <p>En cours de gestion par nos équipes.</p>
            <p>Merci pour votre confiance.</p>
            <p style={{ paddingBottom: '100px' }}>À très bientôt !</p>
        </div>
    )
}

export default Maintenance
