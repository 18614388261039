import { Form, FormikProps } from 'formik'
import React from 'react'
import Input from 'src/components/Fields/Input'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import routes from 'src/routes'
import { Link } from 'react-router-dom'


export interface ILoginFields {
  email: string
  password: string
}

interface ILoginForm extends FormikProps<ILoginFields> { }




const LoginForm: React.FC<ILoginForm> = ({ }) => {

  const onMessage = () => {
    alert('Veuillez contacter votre administrateur')
  }


  return (
    <Form className="w-full" style={{
      display: "flex", justifyContent: "center",
      flexDirection: "column", alignItems: "center", border: "solid 1px #f4f4f4", marginBottom: "15px",
      backgroundColor: "#f9fafb", paddingBottom: "30px", borderRadius: "10px"
    }}>
      <div style={{ flexDirection: "column", alignItems: "center", width: "90%" }} id="inscription">
        <h2>Veuillez vous authentifier</h2>
        <Input label="Identifiant" required name="email" className="mb-5" icon={faEnvelope} style={{ width: "350px", backgroundColor: "white" }} />
        <Input label="Mot de passe" required name="password" className="mb-5" icon={faLock} type="password" style={{ width: "350px", backgroundColor: "white" }} />
      </div>
      <button style={{ width: '260px' }} className="cta my-2 flex-grow-0" type="submit">
        Connexion
      </button>
      <Link to={routes.LOST_PASSWORD}>
        <div className="flex justify-center mt-2 underline decoration-primary underline-offset-4 hover:opacity-50">
          Mot de passe oublié
        </div>
      </Link>
    </Form>
  )
}

export default LoginForm
