import { Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CellProps } from 'react-table'
import DepositFiltersForm, { DepositFilterFields } from 'src/components/Forms/DepositFiltersForm'
import Table, { IFilters } from 'src/components/Tables/Table'
import useService from 'src/hooks/useService'
import routes from 'src/routes'
import { Deposit, fetchDeposits } from 'src/services/subscribe'
import { IFeathersResponse } from 'src/types/feathers.types'
import { getPaginationParams, formatFiltersForFeathersService } from 'src/utils/tables'
import useEffectNotOnFirstRender from 'src/hooks/useEffectNotOnFirstRender'

const DepositList: React.FC = () => {
  const history = useHistory()

  const columns = [
    {
      id: 'action',
      Header: '',
      Cell: (cell: CellProps<Deposit>) => {
        const onNavigate = () => {
          history.push(`${routes.DEPOSIT_FORM}/${cell.row.original.ref}`)
        }

        return (
          <button
            style={{
              padding: '10px 20px',
              backgroundColor: '#D22630',
              borderRadius: '10px',
              color: 'white',
              cursor: 'pointer',
              margin: '10px 0',
            }}
            onClick={onNavigate}
          >
            Sélectionner
          </button>
        )
      },
    },
    {
      Header: 'Nom',
      accessor: 'name',
      id: 'name',
    },
    {
      Header: 'Code postal',
      accessor: 'zipCode',
      id: 'zipCode',
    },
    {
      Header: 'Dépôt',
      accessor: 'ref',
      id: 'ref',
    },
  ]

  const {
    result: { data, ...paginationParams },
    pending,
    service,
  } = useService<IFeathersResponse<Deposit>>({ defaultValue: { data: [] } })

  const [search, setSearch] = useState<string>('')

  const onSearchChange = (value: string) => {
    setSearch(value)
  }
  const loadDeposits = (pageNumber: number, filters?: IFilters<Deposit>) => {
    const tableFilters = formatFiltersForFeathersService(filters)

    const query = {
      ...getPaginationParams(pageNumber, 10),
      ...tableFilters,
    }

    if (search) {
      query.$or = [
        {
          name: {
            $like: `%${search}%`,
          },
        },
        {
          ref: {
            $like: `%${search}%`,
          },
        },
        {
          zipCode: {
            $like: `${search}%`,
          },
        },
      ]
    }
    service(() => fetchDeposits(query))
  }

  useEffectNotOnFirstRender(() => {
    loadDeposits(1)
  }, [search])

  return (
    <div>
      <Formik
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center' }}
        initialValues={{
          search: '',
        }}
        onSubmit={async () => { }}
      >
        {(formikProps: FormikProps<DepositFilterFields>) => (
          <DepositFiltersForm {...formikProps} onSearchChange={onSearchChange} />
        )}
      </Formik>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30, paddingBottom: 30 }}>
        <div style={{ maxWidth: 600, border: 'solid 1px gray' }}>
          <Table
            data={data}
            columns={columns}
            paginated
            loadData={loadDeposits}
            isLoading={pending}
            pagination={paginationParams}
          />
        </div>
      </div>
    </div>
  )
}

export default DepositList
