import { Formik, FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'
import LoginForm, { ILoginFields } from 'src/components/Forms/LoginForm'
import routes from 'src/routes'
//import { useNavigate } from 'react-router-dom'
import { useStore } from '../../stores/index'
import React, { useEffect } from 'react'
import { login } from '../../services/iam'
import { setLogin } from '../../stores/login/actions'
import { defaultErrorToast } from '../../utils/toast'
import { getDeposit } from 'src/services/subscribe'

export interface ILoginProps {
  onSuccess: () => void
}

const Login: React.FC<ILoginProps> = ({ onSuccess }) => {
  const history = useHistory()
  const { state, dispatch } = useStore()

  const onSubmit = async (values: ILoginFields, { setFieldValue }: FormikHelpers<ILoginFields>) => {
    // Gérer l'authentification avec le service
    try {
      const { accessToken, user } = await login(values.email, values.password)
      let depositRef = null
      if (user.operatorDeposit !== null) {
        const deposit = await getDeposit(user.operatorDeposit)

        depositRef = deposit.ref
      }
      onSuccess()
      dispatch(
        setLogin({
          accessToken,
          user,
          depositRef,
        })
      )
    } catch (error: any) {
      setFieldValue('password', '')
      if (error && error.code === 401) {
        alert('E-mail ou mot de passe incorrecte')
      } else {
        defaultErrorToast()
      }
    }
  }

  useEffect(() => {
    if (state.login.currentUser && state.login.depositRef == null) {
      alert(`Bienvenue ${state.login.currentUser.firstname}`)
      history.push(`${routes.DEPOSITS}`)
    } else if (state.login.depositRef !== null) {
      history.push(`${routes.DEPOSIT_FORM}/${state.login.depositRef}`)
    }
  }, [state.login.currentUser])

  return (
    <div
      className="wrap"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        paddingTop: '20px',
      }}
    >
      <Formik
        component={LoginForm}
        initialValues={{ email: '', password: '' }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
      />
    </div>
  )
}

export default Login
