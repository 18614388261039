const routes = {
  HOME: '/',
  FORM: '/subscribe',
  UPLOAD: '/uploads/:subscriptionId/:subscriptionToken',
  NOT_AUTORIZED: '/not-authorized',
  THANKS: '/thanks',
  MAINTENANCE: '/maintenance',
  LOGIN: '/login',
  LOST_PASSWORD: '/app-lost-password',
  UPDATE_LOST_PASSWORD: '/update-lost-password',
  DEPOSITS: '/deposits',
  DEPOSIT_FORM: '/deposit-form'
}

export default routes
