import fetch from '../utils/fetchToken'
import { IFeathersDefaultFilters, IFeathersResponse } from '../types/feathers.types'

const iamEndPoint = `${process.env.REACT_APP_IAM_URL}`

const endPoints = {
  users: `${iamEndPoint}/users`,
  login: `${iamEndPoint}/authentication`,
  myAccount: `${iamEndPoint}/my-account`,
  updatePassword: `${iamEndPoint}/update-password`,
  lostPassword: `${iamEndPoint}/lost-password`,
  updateLostPassword: `${iamEndPoint}/update-lost-password`,
  appLostPassword: `${iamEndPoint}/app-lost-password`,
  sendPassword: `${iamEndPoint}/send-password`
}

export interface User {
  id: number
  firstname: string
  lastname: string
  email: string
  phone: string
  roles: string[]
  enabled: boolean
  createdAt: string
  updatedAt: string
  operatorDeposit: number | null
}

export interface LoginResponse {
  accessToken: string
  user: User
}

export const login = (email: string, password: string): Promise<LoginResponse> =>
  fetch(endPoints.login, 'post', {
    email,
    password,
    strategy: 'local'
  })

export type IFetchUsers = IFeathersResponse<User>

export interface IUsersFilters extends IFeathersDefaultFilters {
  enabled?: boolean
}

export const fetchUsers = (query?: IUsersFilters) => fetch(endPoints.users, 'get', query)

export interface UserPayload extends Omit<User, 'id' | 'createdAt' | 'updatedAt'> {
  password: string
}

export const createUser = (data: UserPayload) => fetch(endPoints.users, 'post', data)
export const updateUser = (id: number, data: UserPayload) => fetch(`${endPoints.users}/${id}`, 'patch', data)
//export const deleteUser = (id: number) => fetch(`${endPoints.users}/${id}`, 'delete')

export interface MyAccountData {
  firstname: string
  lastname: string
  email: string
  phone: string
}

//export const getMyAccountData = (): Promise<MyAccountData> => fetch(endPoints.myAccount, 'get')
export const updateMyAccountData = (data: MyAccountData) => fetch(endPoints.myAccount, 'post', data)

export interface UpdatePasswordPayload {
  password: string
  newPassword: string
}

export const updatePassword = (data: UpdatePasswordPayload) => fetch(endPoints.updatePassword, 'post', data)

//export const lostPassword = (data: { email: string }) => fetch(endPoints.lostPassword, 'post', data)

export const appLostPassword = (data: { email: string }) => fetch(endPoints.appLostPassword, 'post', data)

interface UpdateLostPasswordPayload {
  password: string
  token: string
}

export const updateLostPassword = (data: UpdateLostPasswordPayload) => fetch(endPoints.updateLostPassword, 'post', data)

export const sendPassword = (data: { id: number }) => fetch(endPoints.sendPassword, 'post', data)
