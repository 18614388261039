import React from 'react'
import { BrowserRouter, Switch, Route, RouteComponentProps } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import * as Sentry from '@sentry/react'
import routes from './routes'
import Home from './screens/Home'
import Form from './screens/Form'
import Uploads from './screens/uploads'
import NotAuthorized from './components/NotAuthorized'
import NotFound from './components/NotFound'
import Thanks from './screens/thanks'
import Maintenance from './components/Maintenance'
import './App.css'
import useJiraScript from './hooks'
import { StoreProvider } from './stores/index'
import './assets/pcss/index.css'
import Login from './screens/Login'
import DepositList from './screens/DepositList'
import ConnnectedMenu from './components/ConnectedMenu'
import AuthWrapper from './components/Layout/AuthWrapper'
import useMaintenance from './hooks/useMaintenance'
import LostPassword from './screens/LostPassword'
import UpdateLostPassword from './screens/UpdateLostPassword'

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DNS,
  })
}

const App: React.FC = () => {
  useJiraScript()
  const { isInMaintenance, verifyMaintenance } = useMaintenance()

  return (
    <StoreProvider>
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <BrowserRouter>
          <ConnnectedMenu />
          <main>
            <Switch>
              <Route exact path={routes.HOME}>
                <Home />
              </Route>
              <Route exact path={routes.FORM}>
                {isInMaintenance && verifyMaintenance ? <Maintenance {...verifyMaintenance} /> : <Form depot={null} />}
              </Route>
              <Route exact path={routes.UPLOAD}>
                <Uploads />
              </Route>
              <Route exact path={routes.NOT_AUTORIZED}>
                <NotAuthorized />
              </Route>
              <Route exact path={routes.THANKS}>
                <Thanks />
              </Route>
              <Route exact path={routes.LOGIN}>
                <Login onSuccess={() => null} />
              </Route>
              <Route exact path={routes.LOST_PASSWORD}  >
                <LostPassword />
              </Route>
              <Route exact path={routes.UPDATE_LOST_PASSWORD} >
                <UpdateLostPassword />
              </Route>
              <Route exact path={routes.DEPOSITS}>
                <AuthWrapper>
                  <DepositList />
                </AuthWrapper>
              </Route>
              <Route exact path={`${routes.DEPOSIT_FORM}/:depot`}>
                {({ match }: RouteComponentProps<{ depot?: string }>) => {
                  return (
                    <AuthWrapper>
                      {isInMaintenance && verifyMaintenance ? (
                        <Maintenance {...verifyMaintenance} />
                      ) : (
                        <Form depot={match?.params.depot ? match.params.depot : null} />
                      )}
                    </AuthWrapper>
                  )
                }}
              </Route>
              <Route component={NotFound} />
            </Switch>
          </main>
        </BrowserRouter>
        <footer>
          <div className="wrap">
            <nav>
              <p>Réseaux sociaux</p>
              <a href="https://www.facebook.com/howdenscuisines" rel="noreferer" target="_blank">
                Facebook
              </a>
              <a href="https://www.instagram.com/howdenscuisines" rel="noreferer" target="_blank">
                Instagram
              </a>
            </nav>
            <nav>
              <p>Informations légales</p>
              <a href="https://www.howdens-cuisines.com/conditions-generales-utilisation/">Mentions légales</a>
              <a href="https://www.howdens-cuisines.com/conditions-generales-utilisation/">Données personnelles</a>
              <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-france/">CGVS France</a>
              <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-belgique/">
                CGVS Belgique
              </a>
            </nav>
            <nav>
              <p>À propos</p>
              <a href="https://www.howdens-cuisines.com/a-propos/">A propos</a>
              <a href="https://www.howdens-cuisines.com/nos-depots/">Nos dépôts</a>
            </nav>
            <nav>
              <p>Ressources</p>
              <a href="https://www.howdens-cuisines.com/catalogue/">Catalogue produits</a>
            </nav>
          </div>
        </footer>
      </div>
    </StoreProvider>
  )
}

export default App
