import { Deposit, Operator } from 'src/services/subscribe'
import { ActionMap } from './../index'
import { EActionTypes, DepositPayload } from './actions'

export type DepositActionTypes = ActionMap<DepositPayload>[keyof ActionMap<DepositPayload>]

interface DepositOperatorList {
  depositRef: string
  operators: Operator[]
}

export interface DepositsState {
  list: Deposit[] | null
  operatorsByDeposit: DepositOperatorList[]
}

export const initialState: DepositsState = {
  list: null,
  operatorsByDeposit: [],
}

const reducer = (state: DepositsState, action: DepositActionTypes): DepositsState => {

  switch (action.type) {
    case EActionTypes.SET_DEPOSITS:
      return {
        ...state,
        list: action.payload,
      }

    case EActionTypes.SET_DEPOSIT_OPERATORS:
      const newResult = [...state.operatorsByDeposit]

      const listIndex = newResult.findIndex((item) => item.depositRef === action.payload.depositRef)

      if (listIndex === -1) {
        newResult.push(action.payload)
      } else {
        newResult[listIndex] = action.payload
      }

      return {
        ...state,
        operatorsByDeposit: newResult,
      }

    default:
      return {
        ...state,
      }
  }
}

export default reducer
