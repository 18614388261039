import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import request from 'superagent'
import Loader from '../../components/Loader'
import routes from '../../routes'
import Document, { DocumentI } from '../../components/Document'
import useFeathersSocketClient from 'src/hooks/useFeathersSocketClient'

interface ParamTypes {
  subscriptionId: string
  subscriptionToken: string
}
const Upload: React.FC = () => {
  const history = useHistory()

  const [documents, setDocuments] = useState<Array<DocumentI>>([])
  const [isPending, setIsPending] = useState(true)
  const [canBeFinish, setCanBeFinish] = useState(false)

  const { subscriptionId, subscriptionToken } = useParams<ParamTypes>()

  useFeathersSocketClient({
    path: '/socket.io',
    listeners: (app: any) => {
      app.service('documents').on('documentUpdate', (message: any) => {
        if (message.subscriptionId === parseInt(subscriptionId)) {
          load()
        }
      })

      app.service('subscriptions').on('subscriptionStatus', (message: any) => {
        if (message.id == subscriptionId && [200, 250].includes(message.status)) {
          setCanBeFinish(true)
        }
      })
    },
  })

  const load = async () => {
    setIsPending(true)

    await checkStatus()

    try {
      const res = await request
        .get(`${process.env.REACT_APP_API_URL}/documents/`)
        .query({
          subscriptionId: subscriptionId,
          disabled: false,
        })
        .set('subscription_token', subscriptionToken)
        .set('subscription_id', subscriptionId)

      setDocuments(res.body.data)
    } catch (error) {
      history.push(`${routes.NOT_AUTORIZED}`)
    } finally {
      setIsPending(false)
    }
  }

  useEffect(() => {
    load()
  }, [])

  const checkStatus = async () => {
    const res: any = await request
      .get(`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`)
      .set('subscription_token', subscriptionToken)
      .set('subscription_id', subscriptionId)

    if ([200, 250].includes(res.body.status)) {
      setCanBeFinish(true)
    }
  }

  const showLoader = documents.length === 0 && isPending

  return (
    <div className="wrap" style={{ position: 'relative' }}>
      {showLoader && <Loader />}
      <div id="inscription">
        <h2>Déposez vos documents</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {!showLoader &&
            documents.map((document, index) => {
              return (
                <Document
                  key={index}
                  subscriptionId={subscriptionId}
                  subscriptionToken={subscriptionToken}
                  data={document}
                />
              )
            })}
        </div>
        {canBeFinish && (
          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%' }}>
            <button className="cta" onClick={() => history.push(`${routes.THANKS}`)}>
              J'ai terminé mon inscription
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Upload
