import { SavedDraft } from './reducer'

export enum EActionTypes {
    SET_FORMS = 'SET_FORMS',
    DELETE_FORM = 'DELETE_FORM',
}

export type SubcribeFormPayload = {
    [EActionTypes.SET_FORMS]: SavedDraft
    [EActionTypes.DELETE_FORM]: string
}

export const setForms = (response: SavedDraft) => {

    return {
        type: EActionTypes.SET_FORMS,
        payload: response,
    }
}
export const setDeleteForms = (tempId: string) => {

    return {
        type: EActionTypes.DELETE_FORM,
        payload: tempId,
    }
}
