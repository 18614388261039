
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import routes from 'src/routes'
import { useStore } from 'src/stores'
import { logout } from 'src/stores/login/actions'

interface AuthWrapperProps {
  children: React.ReactNode
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const {
    state: {
      login: { currentUser }
    },
    dispatch
  } = useStore()
  const history = useHistory()

  useEffect(() => {

    if (!currentUser) {
      history.push(routes.LOGIN)
      alert("Vous n'avez pas l'autorisation d'accèder à cette page.")
    }
  }, [currentUser])

  return <>{children}</>
}

export default AuthWrapper
